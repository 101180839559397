import React from "react"
import { graphql } from "gatsby"
import { htmlDecode } from "@utils"
import { Layout, SEO } from "@global"
import { CtaBillboard } from "@layouts"
import { Imgix } from "@elements"
import { BlogCategoryNavigation } from "@elements"

export default ({ data }) => {
  const { video } = data.wpgraphql
  // parse raw date data
  const date = Date.parse(video.date)
  // initiate date format
  const dateTimeFormat = new Intl.DateTimeFormat("en", {
    year: "numeric",
    month: "long",
    day: "2-digit",
  })
  // destructure date for use below
  const [
    { value: month },
    ,
    { value: day },
    ,
    { value: year },
  ] = dateTimeFormat.formatToParts(date)
  return (
    <Layout>
      <SEO
        title={video.title}
        image={video.featuredImage}
        description={htmlDecode(video.excerpt)}
      />
      {/* Start Hero */}
      <section className="flex flex-wrap">
        <div className="w-full lg:w-1/2">
          <div className="px-4 pt-10 pb-12 pb-16 text-gray-400 bg-white lg:py-32 xl:pl-20 min-h-hero lg:flex lg:justify-center lg:flex-col lg:items-start">
            <div className="sm:max-w-xl sm:mx-auto lg:max-w-md xl:max-w-2xl xl:mx-0">
              <h1 className="text-4xl font-medium leading-none sm:text-5xl lg:text-4xl xl:text-6xl font-display">
                {htmlDecode(video.title)}
              </h1>
              <div className="my-8 text-gray-300">
                {`${month} ${day}, ${year}`}
              </div>
            </div>
          </div>
        </div>
        <div className="order-first w-full lg:order-none lg:w-1/2 bg-white-fade-overlay">
          <div className="relative h-48 overflow-hidden sm:h-56 md:h-64 lg:h-full">
            {video.featuredImage && (
              <Imgix
                alt={video.featuredImage.alt}
                src={video.featuredImage.sourceUrl}
                sizes="(min-width: 1024px) 41.6vw, 100vw"
                className="absolute inset-0 object-cover w-full h-full"
              />
            )}
          </div>
        </div>
      </section>
      {/* End Hero */}
      {/* Start body */}
      <div className="container mx-auto flex flex-wrap ">
        <section className="w-full lg:w-8/12 px-4 py-10 lg:pl-20">
          <div className="max-w-3xl">
            <div
              className="blog-post"
              dangerouslySetInnerHTML={{ __html: video.content }}
            ></div>
          </div>
        </section>
        <div className="w-full lg:w-4/12 px-4 py-10 lg:pl-20">
          <BlogCategoryNavigation />
        </div>
      </div>

      {/* End body */}
      <CtaBillboard
        headline="Looking for more information?"
        content="Simply reach out and we can help."
        alignment="center"
        backgroundColor="gradient"
        callToActionGroup={{
          ctaLinkText: "Contact Us",
          ctaLinkUrl: "/contact-us/",
          ctaButtonType: "primary",
        }}
        ctaBillboardSecondCallToActionCallToActionGroup={{
          ctaLinkText: "",
          ctaLinkUrl: "",
          ctaButtonType: "",
        }}
      />
    </Layout>
  )
}

export const VideoQuery = graphql`
  query VideoQuery($id: ID!) {
    wpgraphql {
      video(id: $id) {
        slug
        title
        content
        date
        featuredImage {
          altText
          sourceUrl
          sizes
          mediaDetails {
            height
            width
          }
        }
      }
    }
  }
`
